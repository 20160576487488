<template>
  <div class="bill-footer">
    <div class="left">
      <span class="select" v-if="isSelectAll"
        >已选<span class="num">{{ idsCom.length }}</span
        >条</span
      >
      <span v-if='leftTotalCom' class="total">共{{ totalCom }}条</span>
      <span
        class="checkAll"
        v-show="!isCheckAll"
        @click="selectAllCom"
        v-if="isSelectAll"
        >选择全部</span
      >
      <span
        class="checkAll"
        v-show="isCheckAll"
        @click="selectAllCom"
        v-if="isSelectAll"
        >取消选择</span
      >
    </div>
    <pagination
      class="pagination"
      :total="totalCom"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />
  </div>
</template>

<script>
export default {
  data () {
    return {
      isCheckAll: false //判断当前是否全选数据
    }
  },
  model: { prop: 'value', event: 'modelValue' },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    //列表
    getList: {
      type: Function,
      default: function () {}
    },
    // 选中的id数组
    idsCom: {
      type: Array,
      default: undefined
    },
    // 表格条数
    totalCom: {
      type: Number || String,
      default: 0
    },
    // 是否显示全选
    isSelectAll: {
      type: Boolean,
      default: true
    },
    // 是否显示全选
    isCheck: {
      type: Boolean,
      default: false
    },
    leftTotalCom: {
      type: Boolean,
      default: true
    }
    // //查询参数
    // queryParams: {
    //   type: Object,
    //   default: () => ({
    //     pageNum: 1,
    //     pageSize: 15
    //   })
    // }
  },
  watch: {
    isCheck: {
      handler (newVal) {
        this.isCheckAll = newVal
      },
      immediate: true,
      deep: true
    },
  },
  computed: {
    queryParams: {
      get () {
        return this.value
      },
      set (e) {
        this.$emit('modelValue', e)
      }
    }
  },
  methods: {
    //点击全选
    selectAllCom () {
      this.isCheckAll = !this.isCheckAll
      this.$emit('selectAllCom', this.isCheckAll)
    }
  }
}
</script>

<style lang="scss" scoped>
.bill-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left {
    margin-top: 12px;
    .select {
      .num {
        color: #1890ff;
      }
    }

    .checkAll {
      color: #1890ff;
      cursor: pointer;
    }
    .total {
      margin: 0 10px;
    }
  }
}
::v-deep .pagination-container {
  height: 40px;
}
</style>
